import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_auto_size_panel_item_button = _resolveComponent("tm-auto-size-panel-item-button")!
  const _component_details_hero = _resolveComponent("details-hero")!

  return (_openBlock(), _createBlock(_component_details_hero, {
    pattern: "blue",
    icon: "email",
    "max-visible-buttons": 2,
    title: "Summer Offer 2024",
    infos: _ctx.infos,
    "use-router-tabs": "",
    tabs: _ctx.tabs
  }, {
    right: _withCtx(() => [
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "tmi-duplicate",
        text: "Duplicate"
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "edit",
        text: "Edit"
      }),
      _createVNode(_component_tm_auto_size_panel_item_button, {
        icon: "delete",
        text: "Delete"
      })
    ]),
    _: 1
  }, 8, ["infos", "tabs"]))
}