import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campaigns_email_details_hero = _resolveComponent("campaigns-email-details-hero")!
  const _component_campaigns_details_progress = _resolveComponent("campaigns-details-progress")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    breadcrumbs: _ctx.breadcrumbs,
    "bg-shade": ""
  }, _createSlots({
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_campaigns_email_details_hero, { class: "mb-5" }),
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_campaigns_details_progress, {
            key: 0,
            class: "mb-5",
            infos: _ctx.campaignsEmailProgressInfos
          }, null, 8, ["infos"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 2
  }, [
    (!_ctx.hideWhiteBlock)
      ? {
          name: "page-content-white",
          fn: _withCtx(() => [
            _createVNode(_component_router_view)
          ])
        }
      : undefined
  ]), 1032, ["breadcrumbs"]))
}