
import { defineComponent, computed } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import type { Tab } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    DetailsHero,
    TmAutoSizePanelItemButton,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const infos = computed(() => [
      { label: 'Email campaign' },
      { label: isEmptyMode.value ? 'In progress' : 'Sent on 7 Aug, 3:30pm' },
    ])

    const tabs: Tab[] = [
      {
        name: 'overview',
        label: 'Overview',
        to: { name: 'base.campaigns.email.details.overview' },
      },
      {
        name: 'sent',
        label: 'Sent messages',
        to: { name: 'base.campaigns.email.details.sent' },
      },
      {
        name: 'responses',
        label: 'Responses',
        to: { name: 'base.campaigns.email.details.responses' },
      },
      {
        name: 'links',
        label: 'Links',
        to: { name: 'base.campaigns.email.details.links' },
      },
      {
        name: 'details',
        label: 'Details',
        to: { name: 'base.campaigns.email.details.content' },
      },
    ]

    return {
      infos,
      tabs,
    }
  },
})
