
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import CampaignsEmailDetailsHero from '@/components/pages/campaigns/details/emails/CampaignsEmailDetailsHero.vue'
import CampaignsDetailsProgress from '@/components/pages/campaigns/details/CampaignsDetailsProgress.vue'
import { useModes } from '@/compositions/modes'
import { campaignsEmailProgressInfos } from '@/definitions/campaigns/data'

export default defineComponent({
  components: {
    CampaignsDetailsProgress,
    CampaignsEmailDetailsHero,
    PageContent,
  },
  props: {
    hideWhiteBlock: {
      type: Boolean,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Campaign', url: { name: 'base.campaigns.email' } },
      { label: 'Summer Offer 2024' },
    ]

    return {
      isEmptyMode,
      campaignsEmailProgressInfos,
      breadcrumbs,
    }
  },
})
